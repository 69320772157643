<template>
  <div class="column-picker d-flex align-end gap">
    <icon-tooltip
      v-if="selectedDocumentIsDeleted"
      class="mr-2"
      color="error"
      :icon-name="'i.ExclamationThick'"
      :tooltip-text="$t('t.ColumnIsDeleted')"
    />
    <slot name="prepend-audit" />
    <picker
      :readonly="readonly"
      :dense="dense"
      :clearable="clearable && !readonly"
      :selected-id.sync="documentIdComputed"
      :selected-document.sync="documentComputed"
      document-types="columns"
      :excluded-items="excludedItems"
      :disabled="disabled"
      :label="label || $t(`t.Columns`)"
      :attach="attach"
      :hide-details="hideDetails"
      :max-visible-suggestions=10
      :max-suggestions=30
      :show-icon="true"
      :rules="rules"
      :bottomElementSelector="bottomElementSelector"
      :filters="filters"
      :amt-cols-only="amtColsOnly"
      :avg-cols-only="avgColsOnly"
      :bool-cols-only="boolColsOnly"
      :cmp-cols-only="cmpColsOnly"
      :date-cols-only="dateColsOnly"
      :sum-cols-only="sumColsOnly"
      :no-cols-aging="noColsAging"
      :no-cols-relation="noColsRelation"
      ref="pickerColumn"
      @hook:mounted="validateForm"
    >
      <template v-slot:append>
        <slot name="append" />
      </template>
    </picker>
    <div class="d-flex align-center gap">
      <currency-picker
        v-if="hasCurrency && !hideCurrency"
        :selected.sync="computedCurrency"
        :document-types="['currencies', 'relative-currencies']"
        :rules="required"
        :attach="attach"
        :bottomElementSelector="bottomElementSelector"
        ref="currenciesPicker"
        :readonly="parametersReadonly"
        :dense="dense"
        :hide-details="hideDetails"
      />
      <toggle-consolidated
        :consolidable="computedConsolidable"
        :consolidated.sync="computedConsolidated"
        :readonly="parametersReadonly"
      />
      <v-btn
        icon
        small
        v-if="isEditable"
        @click="editColumn"
      >
        <icon-tooltip
          small
          :icon-name="'i.OpenInSidePanel'"
          :tooltip-text="$t('t.OpenInSidePanel')"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import sidePanelController from '@/sidePanelController'
import { CacheType } from '@/wasm/pkg'

export default {
  components: {
    Picker: () => import('@/components/picker'),
    CurrencyPicker: () => import('@/components/currency-picker'),
    ToggleConsolidated: () => import('@/components/toggle-consolidated'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      showCurrencyPicker: false,
      dataCurrency: null,
      dataConsolidated: false,
      documentId: null,
      selectedDocument: {},
      required: [
        v => (!!v && !this.lodash.isEqual(v, {})) || this.$t('t.IsRequired')
      ]
    }
  },
  computed: {
    currencyCacheType () {
      return this.computedCurrency?.id?.length !== 3 ? CacheType.RelativeCurrencyRef : CacheType.CurrencyRef
    },
    selectedDocumentName () {
      return this.selectedDocument?.name
    },
    selectedDocumentIsDeleted () {
      return this.selectedDocument?.isDeleted
    },
    documentIdComputed: {
      get () {
        return this.documentId
      },
      set (v) {
        if (v !== this.documentId) {
          this.documentId = v
          this.$emit('update:selected-col-id', v)
        }
      }
    },
    documentComputed: {
      get () {
        return this.selectedDocument
      },
      set (v) {
        this.selectedDocument = v
        this.$emit('update:selected-document', this.selectedDocument)
      }
    },
    hasCurrency () {
      return !this.currencyDisabled && this.selectedDocument?.hasCurrency
    },
    computedCurrency: {
      get () {
        return this.hasCurrency ? this.dataCurrency : null
      },
      set (v) {
        if (!this.hasCurrency) {
          return
        }
        this.dataCurrency = v
        this.$emit('update:selected-cur-id', v?.id)
      }
    },
    computedConsolidable () {
      return !this.consolidationDisabled && this.selectedDocument?.consolidable
    },
    computedConsolidated: {
      get () {
        return this.dataConsolidated && this.computedConsolidable
      },
      set (v) {
        if (!this.computedConsolidable) { return }
        this.dataConsolidated = v
        this.$emit('update:consolidated', v)
      }
    },
    autoRelation () {
      return this.selectedDocument?.autoRel
    },
    parametersReadonly () {
      return this.readonly || this.$refs?.pickerColumn?.$refs?.autocomplete?.form?.disabled
    },
    isEditable () {
      if (!this.selectedDocument?.id) {
        return false
      }
      return this.selectedDocument?.paramsTy !== 'none'
    },
    isSystem () {
      return this.selectedDocument?.isSystem
    },
    targetDocTy () {
      return this.filters?.['document-types']?._include?.[0]
    }
  },
  methods: {
    validateForm () {
      this.$refs?.pickerColumn?.$refs?.autocomplete?.form?.validate()
      this.$emit('picker-loaded')
    },
    closeMenu () {
      this.$refs.pickerColumn.closeMenu()
    },
    currencyChanged (e) {
      this.showCurrencyPicker = !this.showCurrencyPicker
    },
    editColumn () {
      sidePanelController.displayDocument(this.documentIdComputed, 'columns', { targetDocTy: this.targetDocTy })
    },
    focus () {
      if (this.$refs.pickerColumn) {
        this.$refs.pickerColumn.focus()
      }
    }
  },
  watch: {
    selectedColId: {
      immediate: true,
      handler (v) {
        this.documentId = v
      }
    },
    selectedCurId: {
      immediate: true,
      handler (v) {
        const type = v?.length !== 3 ? 'relative-currencies' : 'currencies'
        this.dataCurrency = v ? { id: v, type: type } : { id: 'system', type: 'relative-currencies' }
      }
    },
    consolidated: {
      immediate: true,
      handler (v) {
        this.dataConsolidated = v === true
      }
    }
  },
  props: {
    amtColsOnly: {
      type: Boolean,
      default: undefined
    },
    avgColsOnly: {
      type: Boolean,
      default: undefined
    },
    boolColsOnly: {
      type: Boolean,
      default: undefined
    },
    cmpColsOnly: {
      type: Boolean,
      default: undefined
    },
    dateColsOnly: {
      type: Boolean,
      default: undefined
    },
    sumColsOnly: {
      type: Boolean,
      default: undefined
    },
    noColsRelation: {
      type: Boolean,
      default: undefined
    },
    noColsAging: {
      type: Boolean,
      default: undefined
    },
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    bottomElementSelector: {
      type: String,
      default: 'footer'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    consolidated: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    excludedItems: Array,
    filters: {
      type: Object,
      default: undefined
    },
    hideCurrency: {
      type: Boolean,
      default: false
    },
    label: String,
    readonly: {
      type: Boolean,
      default: false
    },
    currencyDisabled: Boolean,
    consolidationDisabled: Boolean,
    rules: Array,
    selectedColId: String,
    selectedCurId: String,
    hideDetails: {
      type: [Boolean, String],
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.currency
  font-size 0.75em
</style>
